export const order = {

  namespaced: true,
  state: {
      lines : [],
      deliveryMode : "delivery",
      deliveryDate : null
  },

  getters : {
    basketLines : state => state.lines,
    isDelivery : state => (state.deliveryMode=='delivery'),
    deliveryMode : state => state.deliveryMode,
    deliveryDate : state => state.deliveryDate,
    basketQuantity : state => state.lines.reduce( (total, line) => total+=(line.quantity),0 ),
    basketSumHT : state => state.lines.reduce( (total, line) => total+=(line.quantity*line.product.price),0 ),
    basketSumTVA : state => state.lines.reduce( (total, line) => total+=(line.quantity*(line.product.price*line.product.tva_tx/100)),0 ),
  },
  actions :  {
    
    initBasket({ commit }, products) {
      console.log(`Init baskets with ${products.length} products`);
      commit('initBasket',products)
    },

    confirmAppointment({ commit }, deliveryDate) {
      if(!deliveryDate)
        throw Error("Choisissez une date de livraison dans le calendrier.");
      commit('setAppointment',deliveryDate);
    },

    confirmShipping({ commit }, deliveryMode) {
      commit('setShipping',deliveryMode);
    },

    confirmOrder({ commit }) {
      commit('emptyBasket');
    },

    emptyBasket({commit}) {
      commit('emptyBasket');
    },

    clear({commit}) {
      commit('clearBasketLines');
      commit('clearShipping');
      commit('clearAppointment');
    } 
  },
  
  mutations: {

    initBasket(state, products) {
      state.lines = [];
      products.forEach(item => {
        let basketLine = {
          id : item.id,
          quantity : 0, 
          product : item
        };
        state.lines.push(basketLine);
      });
  },

  clearBasketLines(state) {
    state.lines=[];
  },

  clearShipping(state) {
    state.shipping = {
                        isFilled : false,
                        deliveryMode : "",
                        deliveryAddress : {
                          address : "",
                          zip: "",
                          town: ""
                        }
                      };
  },


  clearAppointment(state) {
    state.deliveryDate=null;
  },

  setAppointment(state, deliveryDate) {
    state.deliveryDate=deliveryDate;
  },
    
  setShipping(state, deliveryMode) {
      state.deliveryMode=deliveryMode;
    },

    emptyBasket(state) {
        state.lines.forEach(p => p.quantity = 0);
    }
  }
               
          
}