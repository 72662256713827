<template>
    <div>
            <nav class="navbar" role="navigation" aria-label="main navigation">
                <div class="navbar-brand">

                    <div class="navbar-item" v-if="loggedIn">
                        <!-- <router-link class="navbar-item" to="/"> -->
                            <img  v-on:click='isOpen=false' src="@/assets/logo-landing.svg" alt="La Pousseraie" class="navbar-logo">
                            <span class="tag is-info has-text-weight-semibold">pro</span>
                        <!-- </router-link> -->
                        
                    </div>
                

                    <a role="button" class="navbar-burger burger" 
                        aria-label="menu" aria-expanded="false" @click="isOpen = !isOpen" v-bind:class="{'is-active': isOpen}" v-if="loggedIn">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                <!-- LOGGED IN MENU -->
                <div class="navbar-menu has-text-centered-mobile" v-bind:class="{'is-active': isOpen}" v-if="loggedIn">
                    <UserMenu v-if="isOpen" @menuChanged="isOpen=false"/>
                </div>

            </nav>
    </div>
</template>

<script>

import UserMenu from '@/customer-care/UserMenu'

export default {
    components : { UserMenu },
    data : function() {
    return {
      isOpen: false
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.user;
    }
  }
}
</script>

<style scoped>

/* 420px maximum width: cover all smartphones in portrait mode */
@media (min-width: 320px) { 
    .navbar-logo {
        width: 200px;
    }
}

/* 420px maximum width: cover all smartphones in portrait mode */
@media (min-width: 420px) { 
    .navbar-logo {
        width: 220px;
    }
}

/* 421px to 767px width: most smartphones in landscape mode */
@media (min-width: 667px) { 
    .navbar-logo {
        width: 240px;
    }

}

/* 768px to 1024px: most Android tablets and iPads in portrait mode */
@media (min-width: 1024px) { 
    .navbar-logo {
        width: 260px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1365px) { 
    .navbar-logo {
        width: 300px;
    }
}





</style>