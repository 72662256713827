<template>
    <div class="container is-fluid">
      <h4 class="title is-4 has-text-centered has-text-info">
        <span class="icon is-large">
          <i class="fas fa-laugh-beam fa-2x"></i>
         </span><br/>
          Bienvenue {{userProfile.firstname}}
      </h4>
      <div class="columns is-centered is-vcentered is-multiline mt-2">
        
      <div class="column is-12">
      <div  v-if="currentQuantity == 0" class="column has-text-centered">
          vous n'avez pas encore passé de commande pour le mois de {{currentMonth}}
       </div>

       <!-- <div  v-if="currentQuantity > 0" class="column has-text-centered">
          <div class="has-text-centered is-size-5">{{currentQuantity}} / {{70 | box }} en {{currentMonth}}</div>
            <div v-if="currentQuantity<70">pour dévérouiller les frais de livraison</div>
            <div v-if="currentQuantity>70">les frais de livraison sont offerts</div>
            <b-progress :value="currentQuantity/70*100" type="is-info" size="is-medium" class="mt-1 mb-0"></b-progress>
            <router-link class="is-size-7 has-text-info mt-0" style="text-decoration:underline;text-decoration-thickness: 0.01em;" to="/user/orders">voir toutes mes commandes</router-link>
       </div> -->
        </div>

        <div class="column is-6 has-text-centered">
          <div v-if="userOrdersToDeliver.length>0">
            <div class="has-text-info is-size-4 is-size-4-mobile">
                {{userOrdersToDeliver.length}} 
                <span v-if="userOrdersToDeliver.length==1">commande bientôt livrée</span> 
                <span v-else>commandes bientôt livrées</span> 
              </div>
              <div v-for="order in userOrdersToDeliver" :key="order.id" class="mt-2">
                  <span class="mb-0">{{order.ref}} livrée le {{ order.date_livraison | dateFormat('DD/MM') }}</span>
                  <div class="mt-0"><PDFLink :documentReference="order.ref" title="Bon de commande PDF" class=""/></div>
              </div>
          </div>
        </div>

       <div class="column is-12 has-text-centered">
          <router-link class="is-size-5 has-text-info mt-3" to="/user/products">
            <span class="icon mr-2 has-text-info"><i class="fas fa-shopping-basket"></i></span>
            <span style="text-decoration:underline;text-decoration-thickness: 0.01em;">passer une nouvelle commande</span>
          </router-link>
       
       </div>

        <div  class="column is-12 has-text-centered" v-if="userInvoices.length > 0">
        <router-link class="is-size-5 has-text-info mt-3" to="/user/invoices"><span class="icon mr-2 has-text-info"><i class="far fa-file-alt"></i></span>
        <span style="text-decoration:underline;text-decoration-thickness: 0.01em;">voir mon historique de facture</span></router-link>
       </div>

          </div>
    </div>
</template>

<script>
import userMixin from '@/mixins/userMixin'
import PDFLink from '@/components/PDFLink'

export default {
  mixins : [userMixin],
  components : {PDFLink},
  metaInfo: {
        title: 'Tableau de bord'
  },

  methods : {
    switchToCreaticePlan() {
      this.userProfile.company.new_subscription_status = 2;
      this.updateUserData(this.userProfile);
    }
  },

  data() {
    return {
      isFullPage:true,
      formatter : new Intl.DateTimeFormat('fr', { month: 'long' }),
      formatterY : new Intl.DateTimeFormat('fr', { year: 'numeric' })
    }
  },

}
</script>