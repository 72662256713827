import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import Buefy from 'buefy';
Vue.use(Buefy);
require('./scss/lp.scss');

require('default-passive-events');

// Calendar component
import VCalendar from 'v-calendar';
Vue.use(VCalendar, { componentPrefix: 'vc' });

// Spinners
// import { VueSpinners } from '@saeris/vue-spinners'
// Vue.use(VueSpinners)

// Custom filters
Vue.filter('priceFormat', function (value,digits) {
  let _digits = (!digits) ? 0 : digits;
  return Number.parseFloat(value).toFixed(_digits) + " €"
});

Vue.filter('box', function (value) {
  // console.log(value); if (!value) return ''
  if(value>1) return `${value} boîtes`; else return `${value} boîte`;
});

var moment = require('moment');
Vue.filter('dateFormat', function (value, format) {
  if (!value) return ''
  return moment(value).locale('fr').format(format);
});

import router from './router';
import store from './store';

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
