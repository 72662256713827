<template>
    <div class="is-size-4 mt-2">
        <!-- PROFILE -->
        <div class="navbar-item">
          <span class="icon mr-1"><i class="far fa-building"></i></span>
          <span class="">{{userProfile.company.name}}</span>
        </div>
        <!-- DASHBOARD -->
        <router-link class="navbar-item has-text-info" to="/user/dashboard" @click.native="$emit('menuChanged')">tableau de bord</router-link>
        <!-- PRODUCTS -->
        <router-link class="navbar-item has-text-info" to="/user/products" @click.native="$emit('menuChanged')">
          commander <span v-if="basketSumHT>0" class="tag is-info has-text-weight-semibold ml-1">{{basketSumHT | priceFormat(0)}}</span>
        </router-link>
        <!-- COMMANDES -->
        <router-link class="navbar-item  has-text-info" to="/user/orders" @click.native="$emit('menuChanged')">{{currentMonth}}</router-link>
        <!-- FACTURES -->
        <router-link class="navbar-item  has-text-info" to="/user/invoices" @click.native="$emit('menuChanged')">factures</router-link>
        <!-- PROFIL -->
        <router-link class="navbar-item has-text-info" to="/user/data" @click.native="$emit('menuChanged')">profil</router-link>
        
      
        <!-- LOGOUT -->
        <div class="navbar-item mt-6">
          <a class="has-text-grey is-size-6" @click.prevent="logOut">
            <span class="icon mr-1 is-small"><i class="fas fa-sign-out-alt"></i></span>
            <span class="">Se déconnecter</span>
          </a>
        </div>
    </div>
</template>

<script>
// import userMixin from '@/mixins/userMixin';
import { mapGetters } from 'vuex'
const moment = require('moment');
export default {
  // mixins : [userMixin],
  computed: {
        userProfile() {
            return this.$store.state.user;
        },
        ...mapGetters('order',[
            'basketLines',
            'basketSumHT',
            'basketSumTVA',
          ]),
        currentMonth() {
          return moment().locale("fr").startOf('month').format('MMMM YY').toLowerCase();
        }
          
  },
  methods : {
    logOut() {
      this.$store.dispatch('product/clear')
      this.$store.dispatch('order/clear')
      this.$store.dispatch('auth/logout');
      this.$router.push('/');
    }
  },
}
</script>