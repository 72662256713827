import UserService from '../services/user.service';

export const product = {

    namespaced: true,
    state: {
        items : [],
        deliveryFeeItem : null,
    },
  
    getters : {
        productItems : state => state.items.sort((a, b) => a.label.localeCompare(b.label)),
        productDeliveryItem : state => state.deliveryFeeItem,
    },

    actions :  {


    clear({commit}) {
        commit('clear');
    },
    
     async loadProducts({commit}) {
        console.log("Load products");
        let response = await UserService.getProducts();
        console.log(response.data);
        commit('setItems',response.data.filter(p => !p.isFraisLivraison));
        commit('setDeliveryFeeItem',response.data.find(p => p.isFraisLivraison));
        // return new Promise((resolve,error) => {
        //     if(state.items.length ==0)  {
        //         UserService.getProducts().then( 
        //             response =>  {
        //                 commit('setItems',response.data.filter(p => !p.isFraisLivraison));
        //                 commit('setDeliveryFeeItem',response.data.find(p => p.isFraisLivraison));
        //                 resolve(state.items); 
        //              },
        //              err => {
        //                 error(err)
        //              });
        //     } else resolve(state.items);
        // });
        
     },
  
    },

    mutations: {
    

        clear(state) {
            state.items = [];
            state.appointment = null;
        },

        setItems(state,items) {
            state.items = items;
        },
        
        setDeliveryFeeItem(state,deliveryFeeItem) {
            state.deliveryFeeItem = deliveryFeeItem;
        },
    
     }

            
  }