<template>
<span class="is-inline-flex">
    <a @click="downloadPdfOrder" v-if="!isLoading">
        <span class="icon"><i class="fas fa-file-pdf"></i></span>
        <span class="has-text-black is-size-7" style="text-decoration:underline;">
           <span v-if="!title">{{documentReference}}</span>
           <span v-else>{{title}}</span>
        </span>
    </a>
    <!-- Downloading spinner  -->
    <span v-if="isLoading" class="is-flex">
        <span class="icon"><i class="fas fa-file-pdf"></i></span>
        <BeatLoader color="black" :size="8"></BeatLoader>
    </span>
</span>
</template>
<script>
import UserService from '../services/user.service';
import { BeatLoader } from '@saeris/vue-spinners'
export default {
    props:['documentReference','module','title'],
    components : {BeatLoader},
    data() {
    return { 
        isLoading:false
    }
    },
    computed : {
        base64PDF() {
            return `data:application/pdf;base64,${this.base64}`;
        },
        downloadFile() {
            return `${this.fileName}.pdf`;
        }
    },

    methods : {
      async downloadPdfOrder() {
          try {
            this.isLoading = true;
            let http = await UserService.buildPdf(this.documentReference,this.module);
            let pdf = http.data;
            let base64URL = `data:application/pdf;base64,${pdf.content}`;
            // Pure javascript PDF data link generation and triggering
            var element = document.createElement('a');
            element.setAttribute('href', base64URL);
            element.setAttribute('download', `${this.documentReference}`);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        } catch(e) {
            console.log(e);
        } finally {
            this.isLoading = false;
        }
      }
    }

}
</script>
