<template>
    <footer class="footer">
    <!-- <cookie-law theme="base" buttonText="J'accepte">
        <div slot="message">
            Nous utilisons des <span>&#127850;</span> cookies pour améliorer votre expérience de navigation sur notre site web.
        </div>
    </cookie-law> -->
    <!-- <div class="has-text-primary has-text-centered">
      <a target="_blank" href="https://www.instagram.com/lapousseraie/" @click="track('instagram')">
           <span class="icon has-text-primary"><i class="fab fa-instagram"></i></span>
        </a>
        <a target="_blank" href="https://www.facebook.com/lapousseraie/" @click="track('facebook')">
           <span class="icon has-text-primary"><i class="fab fa-facebook-square"></i></span>
        </a>
        <a target="_blank" href="https://twitter.com/lapousseraie/" @click="track('instagram')">
           <span class="icon has-text-primary"><i class="fab fa-twitter"></i></span>
        </a>
        <a target="_blank" href="https://fr.linkedin.com/in/la-pousseraie-099497191/" @click="track('linkedin')">
           <span class="icon has-text-primary"><i class="fab fa-linkedin-in"></i></span>
        </a>
    </div> -->
    <div class="has-text-primary has-text-centered">
      <!-- <span> | </span> -->
      <!-- <router-link to="/contact">contact</router-link>
      <span> | </span>
      <router-link to="/mentions">mentions légales</router-link>
      <span> | </span> -->
      
    <!-- </div>
    
    <div class="has-text-primary has-text-centered"> -->
        <!-- <span> | </span> -->
        
    </div>

    <div class="has-text-primary has-text-centered smaller">
        
        <span class="">©La Pousseraie</span>
        <!-- <span class="icon tiny"><i class="fas fa-map-marker-alt"></i></span>
        <span class="">route de Grenoble, Nice</span> -->
        <span> | </span>
        <a target="_blank" href="https://assets.lapousseraie.fr/CGV_LP_20200925.pdf">cgv</a>
        
    </div>

    <!-- <div class="has-text-centered is-italic has-text-grey	hashtag pt-1" v-if="isAboutUs">#micropousses #06 #cotedazur #gastronomie</div> -->
    </footer>
</template>


<script>
  // import CookieLaw from 'vue-cookie-law'
  export default {
    // components: { CookieLaw },
    computed: {
      // isAboutUs() {
      //   return this.$route.path === '/aboutus'
      // }
    },
    methods : { 
      // track : function(networkName) {
      //     this.$ga.event({
      //     eventCategory: 'Social Network',
      //     eventAction: 'Go',
      //     eventLabel: 'Click',
      //     eventValue: networkName
      //   });
      // }
  }

  }
</script>

<style lang="scss" scoped>
.hashtag {
  font-size: 0.7rem;
}
.smaller {
  font-size: 0.9rem;
}
.tiny {
  font-size: 0.7rem;
} 
</style>