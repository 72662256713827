import { mapGetters } from 'vuex'
const moment = require('moment');

const userMixin = {

    computed: {

          // isDataLoading() {
          //     return !this.isUserdataInitialized || !this.isUserLoaded || !this.basketLines.length>0;
          // },

          currentQuantity() {
            let totalQty = 0;
            if(this.userOrders)
              for (let order of this.userOrders)
                for (let line of order.lines) {
                  if(line.total_ht>0)
                    totalQty+=parseInt(line.quantity);
                }
            return totalQty;
          },
          
          

          currentMonth() {
            return moment().locale("fr").startOf('month').format('MMMM YYYY').toLowerCase();
          },
  

        ...mapGetters('user',[
            'userProfile',
            'userOrders',
            'userInvoices',
            'userOrdersToDeliver',
            'userNextInvoice',
            'userCurrentBalanceHT',
            'userAdress'
          ]),

          ...mapGetters('auth',[
            'loggedIn'
          ]),

        ...mapGetters('product',[
            'productItems',
            'productDeliveryItem',
          ]),

        ...mapGetters('order',[
            'basketLines',
            'basketQuantity',
            'basketSumHT',
            'basketSumTVA',
            'isDelivery',
            'deliveryMode',
            'deliveryDate'
          ]),
        
    },


    data() {

    },

    methods: {

      quantityOfOrder(order) {
        let totalQty = 0;
          for (let line of order.lines) {
            if(line.total_ht>0)
              totalQty+=parseInt(line.quantity);
          }
        return totalQty;
      },
     

      updateUserData(userData) {
        this.$store.dispatch('user/updateUserProfile',userData)
        .then(
          () => {
          this.$buefy.toast.open({
                message: 'Vos préférences ont été sauvegardées.',
                type: 'is-success',
                position:'is-top',
                duration: 2000
            });
          }
        ).catch(
           () => this.$buefy.toast.open({
                message: 'Un problème est survenu 🥺 Nous nous dépêchons de réparer le service.',
                type: 'is-danger',
                position:'is-top',
                duration: 2000
           })
        )
      },

   

    }

     

}

export default userMixin;