import AuthService from '../services/auth.service';
import router from '../router.js'


const token = localStorage.getItem('accessToken');
const initialState = token
  ? { status: { loggedIn: true }, token }
  : { status: { loggedIn: false }, token: null};

export const auth = {
  namespaced: true,
  state: initialState,
  getters : {
    loggedIn : state => state.status.loggedIn,
  },    
  actions: {
   login({ commit }, user) {
      return new Promise((resolve,error) => 
      {
          let handleLoginCallback = null
          if(user.password)
            handleLoginCallback = AuthService.login;
          if(user.token)
            handleLoginCallback = AuthService.autologin;

          handleLoginCallback(user).then( 
            (token) => {
              commit('loginSuccess', token, null);
              resolve(token);
            }, 
            (err) => {
              commit('loginFailure');
              error(err);
            });
      });
    },
    logout({ commit }) {
      commit('logout');
      console.log("Logging out");
      AuthService.logout();
      router.push('/');
    }
  },
  mutations: {
    loginSuccess(state, token) {
      state.status.loggedIn = true;
      state.token = token;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.token = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.token = null;
    }
  }
};