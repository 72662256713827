<template>
    <ul class="steps is-horizontal is-balanced has-content-centered">

        <!-- Basket step -->
        <li class="steps-segment">
            <router-link to="/user/products">
            <span class="steps-marker">
                <span class="icon">
                    <i class="far fa-shopping-basket"></i>
                </span>
            </span>
            <div class="steps-content has-text-black">Panier</div>
            </router-link>
        </li>

        <!-- Shipping step -->
        <li class="steps-segment"  :class="{'is-active': activeStep=='shipping'}">
            <router-link to="/user/shipping">
            <span class="steps-marker">
                <span class="icon">
                        <i class="fas fa-motorcycle"></i>
                </span>
            </span>
            <div class="steps-content has-text-black" :class="{'is-size-4-desktop': activeStep=='shipping'}">
              Livraison
            </div>
            </router-link>
        </li>


        <!-- Confirm step -->
        <li class="steps-segment" :class="{'is-active': activeStep=='confirm'}">
            <span class="steps-marker">
                <span class="icon has-text-white">
                    <i class="fas fa-check"></i>
                </span>
            </span>
            <div class="steps-content" :class="{'is-size-4-desktop': activeStep=='confirm'}">
            <p>Confirmation</p>
            </div>
        </li>


        </ul>
</template>

<script>

export default {
    props:['activeStep'],
    data : function() {
    return {
    }
  },
  mounted() {
  },
  methods : {
  },
  computed: {
  }
}
</script>