import axios from 'axios';

const qs = require('querystring')
const API_URL = `${process.env.VUE_APP_ENDPOINT}/`;


class AuthService {
  login(user) {
    return axios.post(API_URL + 'login', qs.stringify({login: user.login,password: user.password}))
      .then(response => {
        if (response.data.accessToken ) {
          localStorage.removeItem('accessToken');
          localStorage.setItem('accessToken', response.data.accessToken);
        }
        return response.data;
      })
  }

  autologin(user) {
    return axios.post(API_URL + 'autologin', qs.stringify({login: user.login,token: user.token}))
      .then(response => {
        if (response.data.accessToken ) {
          localStorage.removeItem('accessToken');
          localStorage.setItem('accessToken', response.data.accessToken);
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('accessToken');
  }
}

export default new AuthService();