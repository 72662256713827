/*-----------------*/
/* Routing section */
/*-----------------*/
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Login from '@/pages/Login'
import Autologin from '@/pages/Autologin'
import UserDashboard from '@/customer-care/UserDashboard'
import UserData from '@/customer-care/UserData'
import UserOrders from '@/customer-care/UserOrders'
import UserInvoices from '@/customer-care/UserInvoices'
import UserProducts from '@/customer-care/UserProducts'
import UserShipping from '@/customer-care/UserShipping'
import UserConfirmOrder from '@/customer-care/UserConfirmOrder'

const routes = [
  { path: '/', component: Login, name: 'Login' },
  { path: '/autologin', component: Autologin, name: 'Autologin' },
  { path: '/user/dashboard', component: UserDashboard, name:  'UserDashboard' },
  { path: '/user/data', component: UserData, name: 'UserData' },
  { path: '/user/orders', component: UserOrders, name: 'UserOrders' },
  { path: '/user/invoices', component: UserInvoices, name: 'UserInvoices' },
  { path: '/user/products', component: UserProducts, name: 'UserProducts' },
  { path: '/user/shipping', component: UserShipping, name: 'UserShipping' },
  { path: '/user/confirm', component: UserConfirmOrder, name: 'UserConfirmOrder' },
];

const router = new VueRouter({
  routes // short for `routes: routes`
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/','/autologin'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('accessToken');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/');
  } else if (!authRequired && loggedIn && to.path!='/autologin') {
    next('/user/dashboard');
  } else {
    next();
  }
});

export default router;


/*-------------------------------------*/
/* Unauthorized access handling (401)  */
/*-------------------------------------*/

import axios from 'axios';
import store from './store';

axios.interceptors.response.use(
  response => {
  return response;
}, error => {
 if (error.response.status === 401) {
    store.dispatch('auth/logout');
 } else if (error.response.status === 403) {
    throw(error)
  } else
    return error;
});