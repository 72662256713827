<template>
<div>
  
  <div class="is-size-3 is-size-4-mobile has-text-centered">
      <span class="icon mr-2 has-text-info"><i class="fas fa-shopping-basket"></i></span>
      Commandes {{currentMonth}}
  </div>

  <!-- <div class="has-text-centered is-size-3 mb-2">{{currentMonth}}</div> -->

    <div v-if="userOrders.length == 0" class="has-text-centered is-size-4 mt-6">
    Vous n'avez pas encore de commande.
  </div>

  <div v-if="userOrders.length > 0">


 <!-- <div class="columns is-centered mb-6 pl-6 pr-6 mt-2">
      <div class="column is-6-desktop is-6-tablet">
  <div class="has-text-centered is-size-4">{{currentQuantity}} / {{70 | box }}</div>
  <div class="has-text-centered">pour déverrouiller les frais de port</div>
  <b-progress :value="currentQuantity/70*100" type="is-info" size="is-medium"></b-progress>
 </div>
  </div> -->





  <div class="columns is-multiline pl-6 pr-6 is-centered mt-6">
      <div id="order-container" class="column box is-4 has-text-centered-mobile ml-4 mr-4 mb-6  has-text-centered" v-for="order in userOrders"  :key="order.id"> 
        <div class=""><span id="" class="tag is-medium is-light">
          <span class="icon mr-1"><i class="fas fa-shopping-basket"></i></span>{{order.ref}}</span></div>
        <div class="is-size-5 mt-1">{{ order.date_livraison | dateFormat('dddd DD MMMM') }}</div>
        <div class="is-size-5">{{ quantityOfOrder(order)}} boîtes</div> 
        <div class="is-size-5">{{ order.total_ht | priceFormat(2) }}</div> 
        <span id="tag-status" class="tag mt-1 mb-1 is-small"  :class="{'is-success' : order.statut.code == 2 ,'is-info' : order.statut.code == 1 }">
          {{ order.statut.label }}
        </span>
        <div>
          <PDFLink :documentReference="order.ref" title="Bon de commande PDF" class="mt-3"/> 
        </div>
    </div>
    </div>

  </div>

  

  
  </div>

</template>

<script>

import userMixin from '@/mixins/userMixin';
import PDFLink from '@/components/PDFLink'
export default {
    mixins : [userMixin],
    components : {PDFLink},
    metaInfo: {
        title: 'Commandes'
  },
  data() {
    return {
    }
  },

  computed: {
   
  
  }

    
}
</script>

<style scoped>

#order-container {
  position: relative;
}

#tag-status {
  position: absolute;
  top:-10px;
  right:-20px;
  z-index:99;
}
</style>