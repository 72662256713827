<template>
<div >



<div class="columns is-centered is-mobile is-vcentered is-gapless  mb-0">
<div class="column is-2-desktop is-5-mobile has-text-right is-size-6 pb-0 pt-0">Micro-végétaux</div>
<div class="column is-2-desktop has-text-centered">{{ basketSumHT  | priceFormat(2) }}</div>
</div>

<div class="columns is-centered is-mobile is-vcentered is-gapless  mb-0" v-if="isDelivery">
<div class="column is-2-desktop is-5-mobile has-text-right is-size-6 pb-0 pt-0">Frais de livraison</div>
<div class="column is-2-desktop has-text-centered">{{productDeliveryItem.price | priceFormat(2) }}</div>
</div>

<div class="columns is-centered is-mobile is-vcentered is-gapless mb-0 has-text-weight-semibold is-size-4">
<div class="column is-2-desktop is-5-mobile has-text-right">Total HT</div>
<div class="column is-2-desktop has-text-centered">{{basketSumHT + productDeliveryItem.price  | priceFormat(2) }}</div>
</div>

</div> 
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    computed : {
         ...mapGetters('order',[
            'basketLines',
            'isDelivery',
            'basketSumHT',
            'basketSumTVA'
          ]),
          ...mapGetters('product',[
            'productDeliveryItem',
          ]),
    }
    
}
</script>