<template>


  <div class="column" :class="{'is-desktop is-4-tablet is-6-mobile has-text-centered' : isModifiable, 'is-12' : !isModifiable}">
 
    <div v-if="isModifiable">
     
    <div :class="{'disabledproduct' : item.product.isstockavailable==0}">
        <div id="container">
         <img class="product-img" :src="item.product.urlphotoart" @click="add" />
           <transition name="slide-fade" mode="out-in">
              <div id="pinQuantity" class="tag is-primary is-small has-text-weight-bold" v-if="item.quantity" :key="item.quantity">{{item.quantity}}</div>
           </transition>
           <div v-if="item.product.isstockavailable==0" class="has-text-danger has-text-weight-semibold is-size-7" id="outOfStock">
          <div v-if="item.product.nextavailabilitydate">
            Disponible à partir du {{item.product.nextavailabilitydate | dateFormat('dddd DD/MM')}}
          </div> 
          <div v-else>
            Bientôt disponible
          </div>
          </div>
        </div>
        <div id="container">
          <div class="is-size-5 is-size-6-mobile product-label">{{item.product.label.replace(' ','\n')}}</div>
          <div class="is-size-7">{{item.product.labelpackaging}}</div>
          <div id="pinQuantityConfirm" v-if="!isModifiable" class="is-size-5">x{{item.quantity}}</div>
          <div class="has-text-weight-semibold">{{item.product.price | priceFormat(2)}}</div>
          <span id="pinTrash" class="icon has-text-black" v-if="item.quantity && isModifiable" @click="item.quantity=0"><i class="far fa-trash-alt"></i></span>
        </div>     
      </div>
    </div>



    <div v-if="!isModifiable" class="columns is-centered is-mobile mx-0 px-0 is-gapless">
          <div class="column is-3-desktop has-text-left is-size-5 is-size-6-mobile product-label has-text-right">
            <div>{{item.product.label}}</div>
            <div class="is-size-7">{{item.product.price | priceFormat(2)}} - {{item.product.labelpackaging}}</div>
          </div>
          <div class="column is-1 is-size-5 is-size-6-mobile has-text-centered">x{{item.quantity}}</div>
          <div class="column is-2 is-size-5 is-size-6-mobile ">{{item.quantity*item.product.price | priceFormat(2)}}</div>
    </div>


  </div>

</template>

<script>
export default {
      props:['item','isModifiable'],

      methods: {
        minus() {
          if(this.item.quantity>0 && this.isModifiable)
          this.item.quantity--;
        },
        add() {
          if(this.isModifiable) this.item.quantity++;
        }
      }
    
}
</script>

<style scoped>
  .product-img {
    max-height:80px;
    cursor:grab;
  }

  #container {
    position:relative;
  }

  .product-label {
    white-space: pre; 
  }

  #pinQuantity {
    position: absolute;
    top: -15px;
    left: 30%;
    transform: translate(-50%, 0);
  }

   #pinTrash {
    position: absolute;
    bottom: 20px;
    /* right: 10px;  */
    right: 20%;
    cursor:pointer;
  }

  #pinQuantityConfirm {
    position: absolute;
    bottom: 0px;
    right: 0px; 
  }


  #outOfStock {
    position: absolute;
    top:-10px;
    left: 50%;
    transform: translate(-50%, 0);
  }


  .disabledproduct{
    pointer-events:none;
    opacity:0.5;
  }


.slide-fade-enter-active {
  transition: all .1s ease;
}
.slide-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

</style>