<template>
<div class="pr-3 pl-3">
    <div class="columns is-centered" >

      <div class="column">
          <div class="is-size-3 has-text-centered">
                  <span class="icon mr-2 has-text-info"><i class="far fa-comment-dots"></i></span>
                  Contact
          </div>
          <div class="control mt-3" >
              <div class="field">
                <label class="label">Nom</label>
                <div class="control">
                  <input disabled class="input" type="text" v-model="userProfile.lastname">
                </div>
              </div>

              <div class="field">
                <label class="label">Prénom</label>
                <div class="control">
                  <input disabled class="input" type="text" v-model="userProfile.firstname">
                </div>
              </div>

              <div class="field">
                <label class="label">Adresse email</label>
                <div class="control">
                  <input  disabled class="input" type="textarea" v-model="userProfile.email">
                </div>
              </div>

              <div class="field">
                <label class="label">N° téléphone</label>
                <div class="control">
                  <input disabled class="input" type="text" v-model="userProfile.phone_pro">
                </div>
              </div>
          </div>
        </div>

        <div class="column">
              <div class="is-size-3 has-text-centered">
                <span class="icon mr-2 has-text-info"><i class="far fa-building"></i></span>Société
              </div>

              <div class="control mt-3">
                <div class="field">
                  <label class="label">Nom de l'établissement</label>
                  <div class="control">
                    <input disabled class="input" type="text" v-model="userProfile.company.name">
                  </div>
                </div>

                <div class="field">
                  <label class="label">Adresse</label>
                  <div class="control">
                    <input disabled class="input" type="textarea" v-model="userProfile.company.address">
                  </div>
                </div>

                <div class="field">
                  <label class="label">Ville</label>
                  <div class="control">
                    <input disabled class="input" type="text" v-model="userProfile.company.town">
                  </div>
                </div>

                <div class="field">
                  <label class="label">Code postal</label>
                  <div class="control">
                    <input disabled class="input" type="text" v-model="userProfile.company.zip">
                  </div>
                </div>
            </div>
        </div>

    </div>

    
    

    <!-- <div class="columns mt-2" >
      <div class="column has-text-right has-text-centered-mobile">
            <button class="button is-info" @click="updateUserProfile(userProfile)">Enregistrer vos préférénces</button>
      </div>
    </div> -->

</div>
</template>


<script>

import userMixin from '@/mixins/userMixin'

export default {
  mixins : [userMixin],
  components: {},
  metaInfo: {
        title: 'Profil'
  },
  data() {
    return {
    }
  },

 
  mounted : function() {
  },
  computed:{
  
    
  },
  methods : {
  
  }
};
</script>

<style lang="scss" scoped>


</style>