import UserService from '../services/user.service';
var moment = require('moment');

export const user = {
    namespaced: true,
    state: {
      id: -1,
      lastname: "",
      firstname: "",
      phone_pro: "",
      email: "",
      optin: "",
      company: {
          "id": -1,
          "name": "",
          "address": "",
          "zip": "",
          "town": ""
      },
      orders:[],
      invoices:[],
      dataInitialized : false
  },
  getters: {
    userProfile : state => { return state; },
    userOrders : state => { return state.orders.sort(function(a,b){ return new Date(b.date_livraison) - new Date(a.date_livraison); });},
    userOrdersToDeliver : state => { return state.orders.filter(o => o.statut.code==1).sort(function(a,b){ return new Date(a.date_livraison) - new Date(b.date_livraison); });},
    userCurrentBalanceHT : state => { return state.orders.filter(o => o.statut.code !=3 && moment(o.date_commande) >= moment().startOf('month')).reduce( (total, line) => total+=Number.parseFloat(line.total_ht), 0 ); },
    userInvoices : state => { return state.invoices.sort(function(a,b){ return (a.ref < b.ref) ? -1 : (a.ref > b.ref) ? 1 : 0; }).reverse(); },
    userAdress : state => { return `${state.company.address}, ${state.company.zip} ${state.company.town}`;}
  },
  actions: {

    async init({ dispatch }) {
      await dispatch('loadProfile');
      await dispatch('loadOrders');
      await dispatch('loadInvoices');
    },

    async loadOrders({ commit }) {
      console.log("Loading User orders");
      let response = await UserService.getOrders();
      console.log(response.data);
      commit('setOrders',response.data);
    },

    async loadInvoices({ commit }) {
      console.log("Loading User invoices");
      let response = await UserService.getInvoices();
      console.log(response.data);
      commit('setInvoices',response.data);
    },

    async loadProfile({ commit }) {
      console.log("Loading User profile");
      let response = await UserService.getUserProfile();
      console.log(response.data);
      commit('setUserData', response.data);     
    },

    //   updateUserProfile({commit}, user) {
    //     return new Promise((resolve, reject) => {
    //       UserService.updateUserProfile(user).then( () => {
    //         commit('setUserData', user);
    //         resolve();
    //       } 
    //       ).catch( error => { 
    //         console.log(error);
    //         reject(error) 
    //       });
    //   });
    // }

  },
  mutations: {

    // setDataInitialized(state) {
    //   state.dataInitialized = true;
    // },

    setOrders(state, orders) {
      state.orders = orders;
    },

    setInvoices(state, invoices) {
      state.invoices = invoices;
    },

    setUserData(state, user) {
      state.id = user.id;
      state.firstname=user.firstname;
      state.lastname=user.lastname;
      state.phone_pro=user.phone_pro;
      state.email = user.email;
      state.optin = user.optin;
      state.company.id =user.customer.id;
      state.company.name =user.customer.name;
      state.company.address=user.customer.address;
      state.company.zip=user.customer.zip;
      state.company.town=user.customer.town;
    }
  }
               
          
}