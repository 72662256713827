<template>
<div @keyup.enter="handleLogin">
  

        <div class="has-text-centered mb-4">
          <img src="@/assets/logo-landing.svg" class="logo" alt="La Pousseraie" >
          <!-- LA POUSSERAIE -->
          <span class="tag is-info ml-4 has-text-weight-semibold">pro</span>
        </div>

    <div class="columns is-mobile is-centered has-text-centered mb-4 mt-5">     
      <div class="column is-4-desktop is-9-mobile is-6-tablet">
        <form>
        <input v-model="user.login" class="input is-info mb-2" type="email" placeholder="Votre identifiant"  />
        <input v-model="user.password" class="input is-info" type="password" autocomplete=“current-password” placeholder="Mot de passe"/>
        </form>        
        <div class="has-text-danger">{{message}}</div>

        <button class="button is-info is-outlined mt-5"  @click="handleLogin">se connecter</button>
        
      </div>
    </div>
</div>
</template>

<script>

export default {
  data : function() {
    return {
      user: {login : "", password : "" },
      loading: false,
      message: ''
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/user/dashboard');
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
        if (this.user.login && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
            () => {
              this.$router.push('/user/dashboard');
            },
            error => {
              this.loading = false;
              console.log(error);
              if(error.response.status==403) {
                switch (error.response.data.code) {
                  case 'WRONG_PASSWORD':
                    this.message = "Mot de passe incorrect";
                    break;
                  case 'USER_NOT_FOUND':
                    this.message = "Cette adresse email est inconnue";
                    break;
                }
              } else {
                this.message = "Une erreur est survenue"
              }
            }
          );
        }
      }
    }
}
</script>

<style scoped>

.logo {
  /* overflow: hidden; */
  /* white-space: nowrap; */
  max-width:200px;
}
</style>