<template>
<div class="pl-4 pr-4" >
      
    <Breadcrumb activeStep='shipping' />
    <div class="columns is-centered ">
    <div class="column has-text-centered is-gapless is-6-desktop">

             <section class="has-text-left">
            <b-field>
            <b-radio v-model="$store.state.order.deliveryMode"
                native-value="delivery" type="is-info">
                <span  class="is-size-5 has-text-weight-semibold">En livraison</span>
                <span class="ml-2">{{productDeliveryItem.price | priceFormat(1)}}</span>
                <div class="mt-1">{{userAdress}}</div>
            </b-radio>
            
        </b-field>
        
            <b-field>
            <b-radio v-model="$store.state.order.deliveryMode"
                native-value="withdrawal" type="is-info">
                <span  class="is-size-5 has-text-weight-semibold">Click & collect</span>
                <div class="mt-1">
                    59 Route de Grenoble, 06200 NICE
                </div>
            </b-radio>
        </b-field>
        </section>  
        


        <section class="has-text-left mt-4">
            <vc-date-picker is-inline is-expanded 
            locale="fr"
        :disabled-dates='disabledDays'
        :min-date="minDeliveryDate"
        v-model="$store.state.order.deliveryDate" />

        <div class="has-text-centered is-size-5 mt-3 has-text-weight-semibold" v-if="deliveryDate">
            Commande 
            {{(deliveryMode == 'delivery') ? 'livrée' : 'à récupérer'}}
            <br/>le {{deliveryDate | dateFormat("dddd DD MMMM")}}
        </div>

        </section>
        


           
           

    </div>  

     <button id="button-next"  class="button is-large is-rounded is-info" :disabled="deliveryDate==null" @click="$router.push('/user/confirm');">
                <span class="icon"><i class="fas fa-arrow-right"></i></span>
            </button>
    </div>  
</div>    
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import userMixin from '@/mixins/userMixin';
var moment = require('moment');

export default {
    components : {Breadcrumb},
    mixins : [userMixin],
    metaInfo: {
        title: 'Livraison'
    },
    data : function() {
    return {
    }
  },

    mounted : function ()  { },


    computed : {
        disabledDays() { return { weekdays: [1,7]} ; },

        minDeliveryDate() { return moment().add(20, 'hours').toDate(); },
    },

  methods : {

  }, 

  
}
</script>

<style scoped>
#button-next {
  position: fixed; /* Fixed/sticky position */
  bottom: 30px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
}
</style>