<template>
    <div class="pl-3 pr-3">

    <Breadcrumb activeStep='confirm' />

    <h3 class="title is-4 has-text-centered has-text-info">Récapitulatif de votre commande</h3>


  
  <div class="columns is-multiline is-centered is-mobile mx-0 px-0">
  <ProductLine  v-for="b in basketLines.filter(b => b.quantity > 0 && b.product.price > 0 )" :key="b.id" :item="b"  :isModifiable="false"/>
  </div>
  <ProductTotal />

    <hr/>
    <div class="is-size-4 is-size-5-mobile has-text-centered">
      
        <h4><div class="is-4 title">
          <span v-if="deliveryMode=='delivery'">Livraison</span>
          <span v-else>Click & Collect</span>
          </div>
        </h4>
        <p>{{deliveryDate | dateFormat("dddd DD MMMM") }}</p>
          <!-- entre {{appointment.deliveryHours}}<br/> -->
          <span v-if="deliveryMode=='delivery'">
            {{userAdress}}
          </span>
          <span v-else>
            LA POUSSERAIE, 59 route de Grenoble, 06200 NICE
          </span>
          
        <p/>
    </div>


    <div class="has-text-centered mt-5 mb-4">
     <button class="button is-info" @click="validateOrder" :disabled="isOrderProcessing">
            Confirmer ma commande
            <span class="icon ml-1">
              <i class="fas fa-check"></i>
            </span>
      </button>
    </div>

    </div>
</template>

<script>
import UserService from '../services/user.service';
import Breadcrumb from '@/components/Breadcrumb';
import ProductLine from '@/components/ProductLine'
import ProductTotal from '@/components/ProductTotal'
import userMixin from '@/mixins/userMixin';


export default {
    components : {ProductLine,ProductTotal,Breadcrumb},
    mixins : [userMixin],
    metaInfo: {
        title: 'Confirmation'
    },
    data : function() {
    return {
      isOrderProcessing:false
    }
  },
  computed : {
  },

  methods : {
      async validateOrder() {
        try {
        this.isOrderProcessing = true;
        let payload = Object.assign({}, this.$store.state.order);
        payload.lines = payload.lines.filter(p => p.quantity > 0).map(sp => {return {  id : sp.id, quantity : sp.quantity }} )
        await UserService.order(payload);
        this.$store.dispatch('order/emptyBasket');
        await this.$store.dispatch('user/loadOrders');
        this.$router.push('/user/dashboard');
        this.$buefy.toast.open({ message: 'Merci ! Votre commande a bien été enregistrée.', type: 'is-success'});
        this.isOrderProcessing = false;
      } catch(error)  {
        this.$buefy.toast.open({ message: 'Une erreur est survenue. Contactez-nous par téléphone ou Whatsapp au 06 65 38 62 24', type: 'is-danger', duration:'20000'});
        console.log(error);
      } finally {
        this.isOrderProcessing = false;
      }
    }
  }, 

  mounted() {
  },
}
</script>