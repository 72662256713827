<template>
<div class="pl-1 pr-1">
  
  <div class="is-size-3 has-text-centered">
      <span class="icon mr-2 has-text-info mb-6"><i class="far fa-file-alt"></i></span>
      Factures
  </div>
  

     <div v-if="userInvoices.length == 0" class="has-text-centered is-size-4 mt-6">
      Vous n'avez pas encore de factures.
    </div>

    <div class="columns is-multiline pl-6 pr-6 mt-6 is-centered">
      <div id="order-container" class="column box is-3 has-text-centered-mobile ml-4 mr-4 mb-6  has-text-centered" v-for="invoice in userInvoices"  :key="invoice.id"> 
        <div>{{invoice.period.month}}</div>
        <div class=""><span id="" class="tag is-medium">
          <span class="icon mr-1"><i class="far fa-file-alt"></i></span>{{invoice.ref}}</span></div>
        <div class="is-size-4">{{ invoice.date_facturation | dateFormat('DD-MM-YYYY') }}</div>
        <div class="is-size-4">{{ invoice.total_ttc | priceFormat(2) }}</div> 
        <span id="tag-status" class="tag mt-1 mb-1 is-small"  :class="{'is-success' : invoice.statut.code == 2 ,'is-warning' : invoice.statut.code == 1 }">
          {{ invoice.statut.label }}
        </span>
        
        <div class="is-size-6">
          <PDFLink :documentReference="invoice.ref" title="Facture PDF" module="invoice" class="mt-3"/> 
        </div>
    </div>
    </div>

  </div>
  

</template>

<script>
import userMixin from '@/mixins/userMixin';
import PDFLink from '@/components/PDFLink'

export default {
    mixins : [userMixin],
    components : {PDFLink},
    metaInfo: {
        title: 'Commandes'
    },
    data() {
    return { }
    },

    
}
</script>


<style scoped>

#order-container {
  position: relative;
}

#tag-status {
  position: absolute;
  top:-15px;
  right:-20px;
  z-index:99;
}

</style>