<template>
<div class="has-text-centered">
  {{message}}
</div>
</template>

<script>

export default {
  data : function() {
    return {
      user: {login : "", token : "" },
      loading: false,
      message: ''
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },

  created() {
    localStorage.removeItem('accessToken');
    if(this.$route.query.login && this.$route.query.token) {
      console.log("Autologging");
      this.user.login =this.$route.query.login;
      this.user.token =this.$route.query.token; 
      this.handleLogin();
    } else {
      this.$router.push('/user');
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
        if (this.user.login && this.user.token) {
          this.$store.dispatch('auth/login', this.user).then(
            () => {
              this.$router.push('/user/products');
            },
            error => {
              this.loading = false;
              if(error.response.status==403) {
                switch (error.response.data.code) {
                  case 'WRONG_PASSWORD':
                    this.message = "Mot de passe incorrect";
                    break;
                  case 'USER_NOT_FOUND':
                    this.message = "Cette adresse email est inconnue";
                    break;
                }
              } else {
                this.message = "Une erreur est survenue"
              }
            }
          );
        } else {
          this.$router.push('/');
        }
      }
    }
}
</script>
