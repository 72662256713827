import axios from 'axios';
import authHeader from './auth.header';

const API_URL = `${process.env.VUE_APP_ENDPOINT}`;

class UserService {
  getUserProfile() {
    return axios.get(API_URL + '/profile', { headers: authHeader() });
  }

  updateUserProfile(user) {
    return axios.put(API_URL + '/profile',user, { headers: authHeader() } );
  }
  getProducts() {
    return axios.get(API_URL + '/products', { headers: authHeader() } );
  }
  getOrders() {
    return axios.get(API_URL + '/orders', { headers: authHeader() } );
  }
  getInvoices() {
    return axios.get(API_URL + '/invoices', { headers: authHeader() } );
  }
  buildPdf(orderRef,module='order') {
    return axios.get(API_URL + `/pdf/${module}/${orderRef}`, { headers: authHeader() } );
  }

  order(order) {
    return axios.post(API_URL + '/order', order, { headers: authHeader() } );
  }
  
}

export default new UserService();