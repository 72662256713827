<template>
<div id="product-container" >

      <button id="button-next"  class="button is-large is-rounded is-info" @click="nextStep" :disabled="minimumOrderQuantity || basketSumHT<=0">
          <span class="icon"><i class="fas fa-arrow-right"></i></span>
      </button>

       <div class="is-size-3 has-text-centered">
              <span class="icon mr-3 has-text-info"><i class="fas fa-shopping-basket"></i></span>
              Panier
              <span class="is-size-5">({{basketQuantity  | box(basketQuantity) }})</span>
      </div>

      <div v-if="minimumOrderQuantity && basketSumHT>0" class="is-size-6 has-text-centered">Ajoutez encore {{minimumOrderQuantity}} produit(s)</div>

       <!-- <div class="has-text-centered" v-if="basketSumHT>0" >
              <div  class="is-size-4 has-text-info">{{basketSumHT | priceFormat(1) }} - {{basketQuantity | box(basketQuantity)}}</div>
              
      </div> -->
        
  <div class="columns is-multiline is-centered is-mobile mt-3">
    <ProductLine  v-for="b in basketLines" :key="b.id" :item="b" :isModifiable="true"/>
  </div>
  
</div>


</template>

<script>
import userMixin from '@/mixins/userMixin';
import ProductLine from '@/components/ProductLine'

export default {
    components : {ProductLine},
    mixins : [userMixin],

    metaInfo: {
        title: 'Nos Produits'
    },

    data() {
      return {}
    },

    mounted() {
      // this.$store.dispatch('order/removeDeliveryFee')
    },

    computed : {

        minimumOrderQuantity() {
            if(this.basketQuantity < 6)
              return 6-this.basketQuantity;
          return 0;
        }
    },

    methods : {
      nextStep () {
      this.$router.push("/user/shipping")
    },
    }
}
</script>

<style scoped>

#button-next {
  position: fixed; /* Fixed/sticky position */
  bottom: 30px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
}

</style>