<template>
  <div id="app" class="container">
      <Menu v-if="loggedIn" />
      <b-loading v-if="loggedIn" :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
      <div class="columns is-gapless mt-1">
        <div class="column is-3 is-hidden-mobile" v-if="loggedIn">
          <UserMenu /> 
        </div>
        <div class="column">
          <router-view v-if="!loggedIn || (loggedIn && !isLoading)"></router-view>
        </div>
      </div>
 

</div>  
</template>

<script>
/* eslint-disable */
import Menu from './components/Menu.vue'
import UserMenu from './customer-care/UserMenu.vue'
import Footer from './components/Footer.vue'
import userMixin from '@/mixins/userMixin';

export default {
  name: 'App',
  
  components: {
    Menu,
    Footer,
    UserMenu
  },
  mixins : [userMixin],

  data() {
    return {
      isLoading:true
    }
  },

  methods : {
    loadData : async function(){
      try {
        this.isLoading = true;
        await this.$store.dispatch('user/init');
        await this.$store.dispatch('product/loadProducts');
        await this.$store.dispatch('order/initBasket',this.productItems);
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.$store.dispatch('auth/logout');
        this.$router.push('/login');
      } 
    } 
  },  

  created() {
    if(this.loggedIn) this.loadData();
  },

  watch: {
    loggedIn : async function () {
      if(this.loggedIn) this.loadData();
    }
  },

  computed: {
    path() {
      return this.$route.path;
    }
  }

}
</script>

<style>
  .column {
    border: 0px solid black;
  }

</style>
